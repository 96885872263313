<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="8">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="subtitle-2">HOJE NÃO PODE BRINCAR LÁ FORA</v-card-title>
                <span class="mb-2"><a href="/hojenaopodebrincarlaforapix" class="linkComprar">Adquira o seu aqui!</a></span>
                <v-card-text
                  class="black--text"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <div id="galeria" class="mt-5">
                <vue-picture-swipe :items="items"></vue-picture-swipe>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topohojenaopodebrincar" style="margin-bottom: 20vh;">
          <span class="mb-2"><a href="#galeria" class="linkGaleria">Galeria</a></span>
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">HOJE NÃO PODE BRINCAR LÁ FORA</v-card-title>
            <span class="mb-2"><a href="/hojenaopodebrincarlaforapix" class="linkComprar">Adquira o seu aqui!</a></span>
            <v-card-text
              class="black--text"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <div id="galeria" class="mt-5">
            <vue-picture-swipe :items="items"></vue-picture-swipe>
          </div>
          <span><a href="#topohojenaopodebrincar" class="linkGaleria">Topo</a></span>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'LivroPreliminares',
  components: {
    AppBar
  },
  data () {
    return {
      DadosTexto: {
        Titulo: "HOJE NÃO PODE BRINCAR LÁ FORA",
        Texto: `Livremente inspirado em fatos reais acontecidos dentro da casa da autora, sob seu olhar materno, e protagonizados por seus filhos, em sua cena favorita (o brincar!), nasce esse conto infantil.<br /><br />
                O texto narra a rotina de um dia em que não se pode sair de casa e carrega a musicalidade das rimas, para que, na vida das crianças, não falte canção.<br /><br />
                Durante o confinamento por conta da pandemia de COVID, crianças do mundo todo ficaram privadas das diversões de que tanto gostam ao ar livre. Na percepção de muitos deles, foi o primeiro grande desafio imposto pela vida.<br /><br />
                A brincadeira e a descoberta de possibilidades norteada pela criatividade são atitudes infantis que constroem adultos mais aptos a superar obstáculos com um sorriso no rosto.<br /><br />
                Além de uma criança protagonista, o livro carrega a voz da “Fada da Imaginação”, que passa seu recado por meio de uma canção. Basta apontar uma câmera de celular para o QR code estampado em uma de suas páginas que a leitura será enriquecida com uma gostosa experiência musical. <br /><br />
                “Hoje não pode brincar lá fora” foi lançado na Bienal Internacional do Livro do Rio de Janeiro (2021).<br /><br /><br /><br />

                <strong>O que dizem os leitores de “Hoje não pode brincar lá fora”</strong><br /><br />

                <i>“(...) sobre o livro infantil, achei alegre, com imagens lúdicas e muito bem pesadas, rimas que chamam a atenção das crianças, jogo de palavras e uma mensagem muito enriquecedora sobre nos reinventarmos quando as coisas saem do nosso controle. Repleto de esperança com o cuidado e o carinho de uma mãe. E ainda tem uma música hipercriativa dando ideias para como usarmos o pó mágico... quanta sabedoria!”</i>
                <center>Patrícia Renata Falcão, GO, psicóloga e escritora</center><br /><br />
        `
      },
      items: [{
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154744893382676/hojenaopode_6.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154784718311494/hojenaopode_6.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154745321197678/hojenaopode_3.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154784491806740/hojenaopode_3.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154745610621059/hojenaopode_2.jpg?width=434&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154785670410260/hojenaopode_2.jpg',
          w: 434,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154745870659584/hojenaopode_1.jpg',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154785431343114/hojenaopode_1.jpg',
          w: 377,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154746122313778/hojenaopode_0.jpeg?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154785179672586/hojenaopode_0.jpeg',
          w: 503,
          h: 670
        },
        {
          src: 'https://cdn.discordapp.com/attachments/999154238481510550/999154746399150170/hojenaopode_7.JPEG?width=503&height=670',
          thumbnail: 'https://cdn.discordapp.com/attachments/999154238481510550/999154784953188373/hojenaopode_7.jpeg',
          w: 503,
          h: 670
        },
      ]
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
    .linkComprar {
      display: flex;
      flex-direction: row;
      text-decoration: underline;
      justify-content: center;
      color:#70445E;
    }
</style>
